<template>
  <div class="ez-filter-list">
    <div class="ez-filter-list__items">
      <!-- @slot Used to display filter components. -->
      <slot></slot>
      <button
        class="reset-button"
        @click="reset"
        v-if="showFilter">
        <font-awesome-icon icon="undo"/>
        <span>Reset</span>
      </button>
    </div>
    <div class="ez-filter-list__action">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      /**
       * If the component is currently resetting children.
       * @see In the watch section
       */
      inResetMode: false,
    };
  },
  computed: {
    showFilter() {
      return !Object.values(this.filters).every(x => (x === null || x === ''));
    },
  },
  methods: {
    reset() {
      /**
       * Start reset mode.
       * @see In the watch section
       */
      this.inResetMode = true;
      this.$children.forEach(({ reset }) => {
        if (typeof reset === 'function') {
          reset();
        }
      });

      /**
       * When filter value is changed.
       * @event resetFilter
       * @type Object
       */
      this.$emit('resetFilter');
    },
    onFilterUpdated({ name }, event) {
      /**
       * If in the reset mode don't emit update events
       * @see In the watch section
       */
      if (this.inResetMode) return;

      /**
       * When filter value is changed.
       * @event filterUpdated
       * @type String
       */
      this.$emit('filterUpdated', name, event);
    },
  },
  watch: {
    /**
     * When we call the reset mode the component will iterate through all the child components
     * and call `reset()` function. This will cause all the components to throw the change event.
     * This means that our listeners will catch all these changes and it will emit `N` number of
     * update events. *(Where `N` is the number of child input components).
     *
     * To prevent this problem when the reset button is clicked we set the `isResetMode` flag to T.
     * Inside the watcher we detect this change automatically setup a timer to restart this
     * after 300ms.
     * This will insure that update events start working after 300ms.
     * If we don't restart the flag we couldn't detect any changes after the first restart.
     */
    inResetMode() {
      setTimeout(() => { this.inResetMode = false; }, 300);
    },
  },
  mounted() {
    this.$children.forEach((child) => {
      child.$on('change', (event) => { this.onFilterUpdated(child, event); });
      child.$on('onChange', (event) => { this.onFilterUpdated(child, event); });
      child.$on('dateChange', (event) => { this.onFilterUpdated(child, event); });
      child.$on('selected', (event) => { this.onFilterUpdated(child, event); });
    });
  },
};
</script>


<style scoped lang="scss">
  .ez-filter-list {
    display: flex;
    flex-shrink: 0;
    &__items {
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      .input-group {
        :deep() label {
          display: none;
        }
        +.input-group {
          margin-left: 1rem;
        }
      }
    }
    .reset-button {
      @extend %flex-center;
      @include font-size(12px);
      margin-left: 1rem;
      color: $color-gray-6C;
      font-weight: 500;
      cursor: pointer;
      align-self: center;
      svg {
        @include font-size(14px);
        margin-right: .25rem;
      }
    }
  }
</style>
