<script>
import VEntityInfo from '@/components/v3/elements/VEntityInfo';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import EzLiteBadge from '@/components/ui/LiteBadge';

const IMAGE_SIZE_DEFAULT = '32px';
const IMAGE_SIZE_BIG = '56px';

export default {
  components: {
    EzLiteBadge,
    EzPremiumBadge,
    VEntityInfo,
  },
  props: {
    venue: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    showBadge: {
      type: Boolean,
      required: false,
      default: true,
    },
    isBig: {
      required: false,
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    /**
     * A rare case when the request is not sent and UI is initialized.
     * Most often happens on IE 11.
     * @return {*|{}}
     */
    entity() {
      return this.venue || {};
    },
    hasPremiumBadge() {
      return this.showBadge && this.$helpers.isPremium(this.entity.accountType);
    },
    hasLiteBadge() {
      return this.showBadge && this.$helpers.isLite(this.entity.accountType);
    },
    imageSize() {
      return this.isBig ? IMAGE_SIZE_BIG : IMAGE_SIZE_DEFAULT;
    },
  },
};
</script>
<template>
  <VEntityInfo
    :image-url="entity.logo"
    :image-size="imageSize"
    :image-has-border="true"
    image-border-radius="50%"
    :class="{'venue-entity-info--big': isBig}"
  >
    <span :data-cy="dataCy">{{ entity.name }}</span>
    <template #suffix>
      <EzPremiumBadge v-if="hasPremiumBadge" />
      <EzLiteBadge v-if="hasLiteBadge" />
    </template>
  </VEntityInfo>
</template>
<style  lang="scss" scoped>
  .venue-entity-info--big {
    @include font-size(24px, 32px);
    font-weight: bold;
  }

</style>
